import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, Grid, Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const presetEvents = [
  { name: '📚 University' },
  { name: '🎓 Graduation' },
  { name: '💼 First Job' },
  { name: '💔 Break up' },
  { name: '👰 Wedding' },
  { name: '🏠 First Home' },
  { name: '👶 First Child' },
  { name: '✨ Custom' },
];

export default function AddEventModal({ open, handleClose, addEvent, resetBirthDate, setChartDate }) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [customEventName, setCustomEventName] = useState('');
  const [date, setDate] = useState(dayjs());

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    if (event.name !== '✨ Custom') {
      setCustomEventName('');
    }
  };

  const handleSubmit = () => {
    if (selectedEvent) {
      const eventName = selectedEvent.name === '✨ Custom' ? customEventName : selectedEvent.name;
      const newEvent = {
        name: eventName,
        date: date.format('YYYY-MM'),
      };
      addEvent(newEvent);
      setChartDate(newEvent.date);
      handleClose();
      setSelectedEvent(null);
      setCustomEventName('');
      setDate(dayjs());
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Typography variant="h7" gutterBottom>Add a new event</Typography>
        <Box sx={{ mt: 2}}>
          <Grid container spacing={1}>
            {presetEvents.map((event) => (
              <Grid item xs={6} sm={4} key={event.name}>
                <Button
                  variant={selectedEvent === event ? 'contained' : 'outlined'}
                  onClick={() => handleEventSelect(event)}
                  sx={{
                    width: '100%',
                    height: { xs: 36, sm: 48 },
                    backgroundColor: selectedEvent === event ? 'primary.main' : 'transparent',
                    color: selectedEvent === event ? 'white' : 'text.primary',
                    '&:hover': {
                      backgroundColor: selectedEvent === event ? 'primary.dark' : 'rgba(255, 255, 255, 0.1)',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: 1,
                    padding: '1px',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                >
                  {event.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        {selectedEvent && selectedEvent.name === '✨ Custom' && (
          <TextField
            label="Name your event"
            value={customEventName}
            onChange={(e) => setCustomEventName(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ mt: 2, mb: 2 }}
            label="When did it happen?"
            views={['month', 'year']}
            value={date}
            onChange={(newDate) => setDate(newDate)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          disabled={!selectedEvent || (selectedEvent.name === '✨ Custom' && !customEventName)}
          variant="contained"
          color="primary"
        >
          Add Event
        </Button>
      </DialogActions>
    </Dialog>
  );
}